export default [
  {
    header: "Menu",
  },

  {
    title: "dashboard",
    icon: "ActivityIcon",
    route: "game-reports-people",
  },
  {
    title: "Report",
    icon: "FileMinusIcon",
    children: [
      {
        title: "Users",
        route: "game-reports-users",
      },
      {
        title: "Impressions",
        route: "game-reports-impressions",
      },
      {
        title: "Connections",
        route: "game-reports-connections",
      },
      {
        title: "Traffic",
        route: "game-reports-traffic",
      },
      {
        title: "People Analytics",
        route: "game-reports-people",
      },
    ],
  },
  {
    title: "Content",
    icon: "TvIcon",
    route: "game-content",
  },
  {
    title: "game-Top10",
    icon: "FlagIcon",
    route: "game-Top10",
  },
  {
    title: "Campaign",
    icon: "AwardIcon",
    route: "game-campaign",
  },
];
